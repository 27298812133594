// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const NEXT_PUBLIC_APP_ENVIRONMENT = process.env.NEXT_PUBLIC_APP_ENVIRONMENT || 'develop'
if(SENTRY_DSN){
  Sentry.init({
    environment: NEXT_PUBLIC_APP_ENVIRONMENT,
    dsn: SENTRY_DSN,
    release: process.env.SENTRY_RELEASE
    // Adjust this value in production, or use tracesSampler for greater control
    // tracesSampleRate: 1.0
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
  
}
